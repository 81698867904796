<template>
  <div>
    <Manpower_bar />

    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลประเภทสถานศึกษา"
        class="px-5 py-3"
      >
        <v-row>
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headers"
              :items="budget_etc"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:item.sumAll="{ item }">
                <div>
                  {{
                    parseFloat((item.countBud * 100) / item.sumAll).toFixed(2)
                  }}
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </base-material-card>

      <Budget_etc_college_safety />

    

      <Budget_etc_college />

      <Budget_etc_college_safety_de/>

    </v-container>
  </div>
</template>

<script>
import Budget_etc_college from "../../components/admin/budget_etc_college.vue";
import Budget_etc_college_safety from "../../components/admin/budget_etc_college_safety.vue";
import Budget_etc_college_safety_de from "../../components/admin/budget_etc_college_safety_de.vue";
import Manpower_bar from "../../components/admin/manpower_bar.vue";

export default {
  name: "HRvecPersonnelEtcTem",
  components: { Budget_etc_college, Manpower_bar, Budget_etc_college_safety,Budget_etc_college_safety_de },
  data() {
    return {
      ApiKey: "HRvec2021",
      budget_etc: [],
      headers: [
        { text: "งบประมาณ", class: "center", value: "budget_etcdetail" },
        { text: "จำนวน", class: "center", value: "countBud" },
        { text: "ร้อยละ", class: "center", value: "sumAll" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      pagination: {}
    };
  },

  async mounted() {
    await this.budget_etcQuery();
  },

  methods: {
    async budget_etcQuery() {
      let result = await this.$http.post("budget_etc.php", {
        ApiKey: this.ApiKey,
        budget_etcidSummary: "ok"
      });
      this.budget_etc = result.data;
      (result.data);
    }
  }
};
</script>
