<template>
  <div>
    <base-material-card
      icon="mdi-clipboard-text"
      title="การรายงานข้อมูลลูกจ้าง ของสถานศึกษา งานประกันสังคม"
      class="px-5 py-3"
    >
      <div class="text-center pa-2">
        <h2 class="blue--text">
          <v-icon color="blue" size="40">mdi-account-group</v-icon>
          {{ numberWithCommas(countsCheck.succ) }} คน
        </h2>
      </div>
      <v-data-table
        color="success"
        :loading="loading"
        :headers="headers"
        :items="budget_etcs"
        :search="search"
      >
        <template v-slot:item.personnel_etc_safety="{ item }">
          <div>
            <div v-if="!item.personnel_etc_safety">
              <v-icon color="error" size="40">mdi-alert</v-icon>
            </div>
            <div v-else>
              {{ item.personnel_etc_safety }}
            </div>
          </div>
        </template>
        <template v-slot:item.countsafe="{item}">
          <div>
            {{ numberWithCommas(item.countsafe) }}
          </div>
        </template>
        <template v-slot:item.percent="{ item }">
          <div>
            {{
              parseFloat((item.countsafe * 100) / countsCheck.succ).toFixed(2)
            }}
          </div>
        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert"
          >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
        >
      </v-data-table>
    </base-material-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      budget_etcs: [],
      search: "",
      pagination: {},
      headers: [
        { text: "ประกันสังคม", align: "center", value: "personnel_etc_safety" },
        { text: "จำนวน/คน", align: "center", value: "countsafe" },
        { text: "ร้อยละ", align: "center", value: "percent" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      countsCheck: []
    };
  },
  async mounted() {
    this.budget_etcQueryAll();
  },
  methods: {
    async budget_etcQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("budget_etc.php", {
          ApiKey: this.ApiKey,
          budget_etcsafety: "ok"
        })
        .finally(() => (this.loading = false));
      this.budget_etcs = result.data;
      let data = result.data;
      let counts = 0;

      data.forEach(value => {
        counts += parseInt(value.countsafe);
      });
      this.countsCheck.succ = counts;
    },

    numberWithCommas(x) {
      x = String(x)
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "blue darken-4";
    }
  }
};
</script>
