<template>
  <div>
    <base-material-card
      icon="mdi-clipboard-text"
      title="การรายงานข้อมูลลูกจ้าง ของสถานศึกษา"
      class="px-5 py-3"
    >
      <div class="text-center pa-2">
        <span class="font-weight-bold success--text">
          <v-icon color="success" size="30">mdi-check-circle</v-icon> {{ countsCheck.succ }} แห่ง
        </span>

        <span class="font-weight-bold red--text">
          <v-icon size="30" color="error">mdi-alert</v-icon
          >{{ countsCheck.fals }} แห่ง
        </span>
      </div>
      <v-data-table
        color="success"
        :loading="loading"
        :headers="headers"
        :items="budget_etcs"
        :search="search"
      >
        <template v-slot:top>
          <div>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
              single-line
              hide-details
              dense
              filled
              class="mb-2"
            />
          </div>
        </template>

        <template v-slot:item.check="{ item }">
          <div>
            <div v-if="item.countTem === item.countSuc">
              <v-icon color="success" size="40">mdi-check-circle</v-icon>
            </div>
            <div v-else>
              <v-icon color="error" size="40">mdi-alert</v-icon>
            </div>
          </div>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert"
          >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
        >
      </v-data-table>
    </base-material-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      budget_etcs: [],
      search: "",
      pagination: {},
      headers: [
        { text: "รหัส", align: "center", value: "user_code" },
        { text: "วิทยาลัย", align: "left", value: "user_firstname" },
        { text: "จำนวนลูกจ้าง", align: "left", value: "countTem" },
        { text: "จำนวนรายงานตรงตามงบประมาณ", align: "left", value: "countSuc" },
        { text: "ตรวจสอบ", align: "left", value: "check" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      countsCheck: []
    };
  },
  async mounted() {
    this.budget_etcQueryAll();
  },
  methods: {
    async budget_etcQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("budget_etc.php", {
          ApiKey: this.ApiKey,
          budget_etcidSummaryCollege: "ok"
        })
        .finally(() => (this.loading = false));
      this.budget_etcs = result.data;
      let data = result.data;
      let counts = 0;
      let countd = 0;
      data.forEach(value => {
        if (value.countTem == value.countSuc) {
          counts += 1;
        } else {
          countd += 1;
        }
      });
      this.countsCheck.succ = counts;
      this.countsCheck.fals = countd;
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "blue darken-4";
    }
  }
};
</script>
